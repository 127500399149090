.flexible-product-allocations {
  @apply px-6 py-12;

  .allocation-section {
    @apply my-4;

    .header-container {
      @apply flex justify-between items-center;

      .section-title {
        @apply font-medium text-xl;
      }

      .assign-button {
        @apply cursor-pointer
          border
          border-orange-vibrant
          text-white
          bg-orange-vibrant
          text-center
          font-medium
          rounded-xl
          py-4
          px-8
          hover:bg-orange-dark
          hover:border-orange-dark;
      }
    }
  }

  .coach-allocations {
    .row {
      @apply bg-grey-6 p-4 my-2 grid grid-cols-1;

      &.row-header {
        @apply bg-transparent;
      }
    }
  }

  .office-allocations {
    .header.header {
      @apply bg-transparent;
    }

    .row {
      @apply bg-grey-6 p-4 my-2 grid grid-cols-[2fr,1fr,1fr,100px];

      .view-link {
        @apply text-blue-reflective text-center;
      }

      &.row-header {
        @apply bg-transparent;
      }
    }
  }

  .allocation-form-container {
    @apply w-full max-w-xl;

    .input {
      @apply w-full;
    }

    .submit-button {
        @apply cursor-pointer
          border
          border-orange-vibrant
          text-white
          bg-orange-vibrant
          text-center
          font-medium
          rounded-xl
          py-4
          px-4
          uppercase
          hover:bg-orange-dark
          hover:border-orange-dark;
    }
  }
}
