@import 'app/assets/stylesheets/_vars';

.coach-home {
  .key-links {
    @apply p-2 hover:text-white hover:bg-blue-reflective rounded-2xl border-2 border-blue-reflective border-solid m-auto w-4/5 text-blue-reflective my-1.5;

    a {
      @apply text-blue-reflective;
    }

    &:hover {
      a {
        @apply text-white;
      }
    }
  }

  .coach-name {
    @apply text-2xl font-normal text-orange-vibrant;
  }

  .modal {
    display: none;

    &.--open {
      display: grid;
    }

    &.--small {
      .modal-container {
        max-width: 600px;
      }
    }

    &.--large, &.--wide {
      .modal-container {
        max-width: 1200px;
      }
    }
  }

  .modal-background {
    align-content: center;
    align-items: center;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: end;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
  }

  .modal-container {
    align-items: center;
    background: #fff;
    border-radius: 10px;
    display: grid;
    margin: 0 auto;
    height: 100%;
    max-height: 95vh;
    max-width: 800px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: calc(100% - 20px);

    @screen sm {
      padding: 30px;
      width: 90%;
    }
  }

  .modal-content {
    height: 100%;
    color: black;

    img {
      width: 100%;
      height: auto;
    }

    span.recommends-badge {
      @apply -ml-2.5 mb-2 px-5 py-1 text-sm font-sans text-navy-content bg-blue-cool rounded-full self-start whitespace-nowrap;
    }
  }

  .modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    svg {
      fill: gray;
    }
  }

  .modal-left-background {
    background-color: rgb(250 239 235);
  }

  .carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel__viewport {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }

  .carousel__slide {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
    align-content: center;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .carousel__prev, .carousel__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .carousel__prev {
    left: 0;
  }

  .carousel__next {
    right: 0;
  }

  .carousel-multi {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel__viewport-multi {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel__slide-multi {
    flex: 0 0 25%;
    box-sizing: border-box;
    padding: 0.2rem;
    text-align: center;
  }

  .logo {
    width: auto !important;
    max-height: fill-available;
    max-height: -moz-fill-available;
    max-height: -webkit-fill-available;
  }
}