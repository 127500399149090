#tracking-container {
  @apply mt-4;

  #tracking-table {
    @apply table-auto w-full mt-5 bg-transparent border-separate border-spacing-y-3;

    .heading {
      @apply pr-2 text-xs uppercase text-left;
    }

    .table-row {
      @apply items-center py-4 ml-2;

      &.data-row {
        @apply bg-grey-6;
      }

      .text {
        @apply pl-2 my-4 align-middle;
      }


      &.table-totals {
        @apply italic font-medium;

        .mismatched {
          @apply text-red;
        }
      }

      &.table-data-row {
        @apply bg-sand;

        .mismatched {
          font-weight: 550;
          color: red
        }
      }
    }
  }
}
