$orange: #FE4B0A;
$dark-orange: #bf512c;
$light-blue: #6b8ea3;
$blue: #094367;
$dark-blue: #1F3A5E;
$white: #FFF;
$light-grey: rgb(250,250,250);
$grey: #EEE;
$grey-2: #dddddd;
$grey-3: rgb(245, 242, 237);
$dark-grey: #959595;
$light-green: #79c879;
$danger: #FFCCCC;
$black: #000;

$tablet-width: 1023px;
$mobile-width: 600px;

@mixin noselect() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin mobile() {
  @media only screen and (max-width: $mobile-width) {
    @content
  }
}

@mixin tablet() {
  @media only screen and (max-width: $mobile-width) {
    @content
  }
}

/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2023 Colophon Foundry
 *
 * Licenced to George Bell, Sanctus Coaching
 */

@font-face {
  font-family: 'grenette-regular';
  src: url('grenette-regular.ttf') format('truetype'),
       url('grenette-regular.woff2') format('woff2'),
       url('grenette-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

img.emoji {
   height: 1em;
   width: 1em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;
   display: inline-flex;
}
