@keyframes yellow-fade {
  0% {
    background: rgb(255, 240, 105);
  }

  100% {
    background: none;
  }
}

.highlighted {
  animation: yellow-fade 2s ease-in 1;
}
