.flash-list {
  @apply grid text-xl font-semibold justify-items-center fixed text-center top-0 w-full z-50;

  li {
    @apply mb-2 p-5 w-full;

    .flash-message {
      @apply p-2 grid items-center;
    }

    &.--notice, &.--info {
      @apply bg-orange-vibrant text-white;
    }

    &.--alert, &.--error {
      background: #F24D4D;
      color: #FFFFFF;

      .mask, .wrapper:after {
        background: rgba(242, 77, 77, 0.3);
      }
    }
  }

  .flash-close {
    @apply hidden;
  }
}
